<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{ operationTitle }}
            <p><small>Manage Guest Room List with Rates</small></p>
          </template>
          <template v-slot:toolbar>
            <b-input-group-append>
              <router-link to="/guest/rooms/create"
                           v-if="globalEventId > 0 && $global.hasPermission('guestroomsstore')"
              >
                <b-button class="btn btn-primary font-weight-bolder"
                          v-b-tooltip.hover :title="`Add New`"
                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                </b-button>
              </router-link>
              <!--                            <b-button variant="info"-->
              <!--                                      class="ml-2 filter-button"-->
              <!--                                      v-b-tooltip.hover :title="`Filter`"-->
              <!--                                      @click="filters.visible = !filters.visible">-->
              <!--                                <i class="fa fa-filter fa-sm"></i> Filter-->
              <!--                            </b-button>-->
            </b-input-group-append>
          </template>

          <template v-slot:body>
            <v-card>
              <div class="rooms-table"
                   v-if="$global.hasPermission('guestroomsview')">

                <div class="w-100 mb-6">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length"><label>Show
                        <b-form-select v-model="pagination.totalPerPage"
                                       class="datatable-select"
                                       :options="[10, 50, 100]"
                                       size="sm"></b-form-select>
                        entries</label></div>

                    </div>
                    <!--                                        <div class="col-sm-12 col-md-6 mt-2">-->
                    <!--                                            <span v-for="(value,key) in filters"-->
                    <!--                                                  :key="key">-->
                    <!--                                                <span v-if="_.isArray(value) && key !== 'visible'">-->
                    <!--                                                    <b-tag class="py-2 text-capitalize" v-if="value.length > 0"> {{$global.removeSymbols(key)}}-->
                    <!--                                                        <i class="fa fa-times-circle ml-2"-->
                    <!--                                                           @click="clearFilter(key, [])"></i>-->
                    <!--                                                    </b-tag>-->
                    <!--                                                </span>-->
                    <!--                                                <span v-else>-->
                    <!--                                                    <b-tag class="py-2"-->
                    <!--                                                           v-if="value && key !== 'visible'"> <span-->
                    <!--                                                        class="text-capitalize">{{$global.removeSymbols(key)}}</span>-->
                    <!--                                                    <i class="fa fa-times-circle ml-2"-->
                    <!--                                                       @click="clearFilter(key , null)"></i>-->
                    <!--                                                    </b-tag>-->
                    <!--                                                </span>-->
                    <!--                                            </span>-->
                    <!--                                        </div>-->
                    <div class="col-sm-12 col-md-6">
                      <div id="kt_datatable_filter" class="dataTables_filter"><label>Search:
                        <b-form-input type="search"
                                      class="form-control form-control-sm ml-2 w-25"
                                      label="Search"
                                      v-on:keyup.enter="handleSearch"
                                      v-model="search"></b-form-input>
                      </label>
                      </div>
                    </div>
                  </div>
                </div>

                <v-data-table
                    responsive
                    ref="table"
                    :headers="columns"
                    :items="dataSource"
                    :loading="loading"
                    :items-per-page="parseInt(pagination.totalPerPage)"
                    hide-default-footer
                    class="table table-head-custom table-vertical-center table-responsive"

                >
                  <template v-slot:item.id="record">
                    {{dataSource.length - record.index}}
                  </template>
                  <template v-slot:item.action="{item}" class="action-column">
                    <router-link :to="`/guest/rooms/edit/${item.id}`">
                      <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2"
                         v-show="$global.hasPermission('guestroomsupdate')">
                            <span
                                class="svg-icon svg-icon-md svg-icon-primary">
                              <!--beggin::Svg Icon-->
                              <inline-svg
                                  src="/media/svg/icons/Communication/Write.svg"
                                  class="action-edit-button"
                              ></inline-svg>
                              <!--end::Svg Icon-->
                            </span>
                      </a>
                    </router-link>
                    <a size="sm" class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                       v-show="$global.hasPermission('guestroomsdestroy')"
                       @click="handleDeleteOperation(item.id)">
                        <span
                            class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg
                                src="/media/svg/icons/General/Trash.svg" class="action-delete-button"
                            ></inline-svg>
                          <!--end::Svg Icon-->
                        </span>
                    </a>
                  </template>
                </v-data-table>
                <div class="row">
                  <div class="pagination-box">
                    <b-pagination
                        v-model="pagination.current"
                        :total-rows="pagination.total"
                        :per-page="pagination.totalPerPage"
                        size="lg"
                    ></b-pagination>
                  </div>
                </div><!-- /.pull-right -->
              </div>
            </v-card>
          </template>
        </KTCard>
        <div class="filter-container">
          <div id="filter_list"
               ref="room_list"
               v-show="!operation &&filters.visible"
               :class="filters.visible ? 'offcanvas offcanvas-right p-10 offcanvas-on' :'offcanvas offcanvas-right p-10'"
          >
            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
              <b-row>
                <b-col sm="12" class="text-right">
                  <a
                      href="javascript:;"
                      @click="filters.visible = !filters.visible"
                      class="btn btn-xs btn-icon btn-light btn-hover-primary text-right"
                  >
                    <i class="ki ki-close icon-xs text-muted"></i>
                  </a>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                      label="Name Of Room"
                      label-for="title">
                    <b-form-input id="filter-title" v-model="filters.title"
                                  class="mb-2"></b-form-input>
                  </b-form-group>
                </b-col><!--/b-col-->
                <b-col sm="12">
                  <b-form-group
                      label="Room No"
                      label-for="room_no">
                    <b-form-input id="filter-room_no" v-model="filters.room_no"
                                  class="mb-2"></b-form-input>
                  </b-form-group>
                </b-col><!--/b-col-->
              </b-row>
              <div class="drawer-footer">
                <b-button @click="handleResetFilterClick()" size='sm' variant="info">
                  Clear All
                </b-button>
              </div><!-- /.drawer-footer -->
            </form>
          </div>
        </div>
        <!-- /.filter-container -->
      </div>

    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import KTCard from "@/view/content/Card.vue";
  import ListingMixin from "@/assets/js/components/ListingMixin";
  import ModuleMixing from "./module.mixin";

  const DEFAULT_FILTER_STATE = {
    title: null,
    room_no: null,
  };

  export default {
    mixins: [ListingMixin, ModuleMixing],
    data() {
      return {
        filters: { ...DEFAULT_FILTER_STATE },
      };
    },
    components: {
      KTCard
    },
    methods: {
      handleResetFilterClick() {
        this.filters = { ...DEFAULT_FILTER_STATE };
        this.isFilterApplied = 'reset';
        this.checkFilterCountStatus();
      },
    },
    computed: {
      ...mapState([
        'global',
      ]),
    },
  };
</script>
